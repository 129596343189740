<template>
  <footer>
    <div class="mobile-itemright-footer" data-aos="fade-down">
      <div class="footer-box-right">
        <p class="mind-project">Have a project in your mind?</p>
        <div class="view-work-circle btn btn1">
          <a href="/contact" class="a1">CONTACT ME</a>
        </div>
        <div>
          <p class="contact_time">09 : 00 AM - 10 : 30 PM</p>
          <p class="monday">Monday to Saturday</p>
        </div>
      </div>
    </div>
    <div class="footer-bg">
      <div class="container footer-containe">
        <div class="hekl">
          <div class="row">
            <div class="col-xxl-5 col-xl-5 col-lg-5">
              <a href="/"><img src="../../public/images/logo.svg" alt="logo"></a>
              <p class="work_well">When do they work well, and when do they on us and finally,
                when do we actually need how can we avoid them.</p>
              <div class="contact-footer">
                <img src="../../public/images/phone_svg.svg" alt="phone_svg">
                <a href="tel:+263717468729">+263 717 468 729</a>
              </div>
              <div class="contact-footer">
                <img src="../../public/images/email_svg.svg" alt="email_svg">
                <a href="mailto:mqondisixndlovu@gmail.com">mqondisixndlovu@gmail.com</a>
              </div>
              <div class="contact-footer">
                <img src="../../public/images/location_svg.svg" alt="location_svg">
                <p>Helderberg, Cape Town, South Africa</p>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-3 col-lg-3">
              <p class="useful-link">USEFUL LINKS</p>
              <div class="contact-footer">
                <a href="/about">About</a>
              </div>
              <div class="contact-footer">
                <a href="/services">Services</a>
              </div>
              <div class="contact-footer">
                <a href="/blog">Blog</a>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-4 col-lg-4">
              <p class="useful-link">USEFUL LINKS</p>
              <div class="contact-footer">
                <a href="/contact">Contact</a>
              </div>
              <div class="contact-footer">
                <a href="#">Privacy Policy</a>
              </div>
              <div class="contact-footer">
                <a href="#">Terms & Conditions</a>
              </div>
            </div>
          </div>
          <div class="copyright_main">
            <p class="copyright">© 2024 | All rights reserved
            </p>
            <div class="input-main">
              <input type="text" name="text" placeholder="Enter your email" autocomplete="off">
              <svg class="send-svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                   viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_147_85)">
                  <path d="M10 14L21 3" stroke="white" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                  <path
                      d="M21 3L14.5 21C14.4561 21.0957 14.3857 21.1769 14.2971 21.2338C14.2084 21.2906 14.1053 21.3209 14 21.3209C13.8947 21.3209 13.7916 21.2906 13.703 21.2338C13.6143 21.1769 13.5439 21.0957 13.5 21L10 14L3.00001 10.5C2.90427 10.4561 2.82314 10.3857 2.76626 10.2971C2.70938 10.2084 2.67914 10.1053 2.67914 10C2.67914 9.89468 2.70938 9.79158 2.76626 9.70295C2.82314 9.61431 2.90427 9.54387 3.00001 9.5L21 3Z"
                      stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_147_85">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <div class="desktop-itemright-footer">
          <div class="footer-box-right">
            <p class="mind-project">Have a project in your mind?</p>
            <div class="view-work-circle btn btn1">
              <a href="/contact" class="a1">CONTACT ME</a>
            </div>
            <div>
              <p class="contact_time">09 : 00 AM - 09 : 30 PM</p>
              <p class="monday">Monday to Saturday</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
