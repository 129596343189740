<template>
  <div class="site_contain">
    <!-- ====================================== Cusrsor ===================================== -->
    <div class="cursor"></div>
    <!-- ====================================== Cursor End ===================================== -->
    <!-- ====================================== Preloader ===================================== -->
    <div class="page-loader">
      <div class="loader"></div>
      <div class="shadow"></div>
    </div>
    <!-- ====================================== Preloader End ===================================== -->
    <!-- ====================================== Main Menu ===================================== -->
    <header id="top-navbar" class="top-navbar">
      <div class="container">
        <div class="top-navbar_full">
          <div class="back-btn">
            <a href="index.html">
              <img src="../../public/images/logo.svg" alt="logo">
            </a>
          </div>
          <div class="hell">
            <ul class="top-navbar-title">
              <li class="menu-active"><a href="index.html">Home</a></li>
              <li><a href="about.html">About</a></li>
              <li class="drop-svg dropdown dropdown-7">Services
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                     fill="none">
                  <g clip-path="url(#clip0_25_56)">
                    <path d="M6 9L12 15L18 9" stroke="white" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                  </g>
                </svg>
                <ul class="dropdown_menu dropdown_menu--animated dropdown_menu-7">
                  <li class="dropdown-submenu">
                    <a class="sub-menu-arrow-main" href="#">Services</a>
                    <ul class="dropdown-submenu-main id-portfolio">
                      <li class="dropdown_item-submenu">
                        <a href="services.html">Services Default</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="services2.html">Services Row</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="services3.html">Services 3 Grid</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="services4.html">Services 2 Grid</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="services5.html">FullScreen</a>
                      </li>
                    </ul>
                  </li>
                  <li class="dropdown-submenu">
                    <a class="sub-menu-arrow-main" href="#">Single Services</a>
                    <ul class="dropdown-submenu-main id-single-portfolio">
                      <li class="dropdown_item-submenu">
                        <a href="single_services.html">Standard</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="single_services2.html">Youtube Video</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="single_services3.html">Vimeo Video</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="single_services4.html">Gallery Slider</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="single_services5.html">Sound Cloud Music</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="drop-svg dropdown dropdown-7">Portfolio
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                     fill="none">
                  <g clip-path="url(#clip0_25_56)">
                    <path d="M6 9L12 15L18 9" stroke="white" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                  </g>
                </svg>
                <ul class="dropdown_menu dropdown_menu--animated dropdown_menu-7">
                  <li class="dropdown-submenu">
                    <a class="sub-menu-arrow-main" href="#">Portfolio</a>
                    <ul class="dropdown-submenu-main id-portfolio">
                      <li class="dropdown_item-submenu">
                        <a href="portfolio.html">Portfolio Default</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="portfolio2.html">Portfolio Grid</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="portfolio3.html">Portfolio Split</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="portfolio4.html">Portfolio Split Video</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="portfolio5.html">Portfolio Split Slider</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="portfolio6.html">Portfolio Full Screen</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="portfolio7.html">Split Gallery</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="portfolio8.html">Grid Gallery</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="portfolio9.html">FullScreen Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li class="dropdown-submenu">
                    <a class="sub-menu-arrow-main" href="#">Single Portfolio</a>
                    <ul class="dropdown-submenu-main id-single-portfolio">
                      <li class="dropdown_item-submenu">
                        <a href="single_portfolio.html">Standard</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="single_portfolio2.html">Portfolio V.2</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="single_portfolio3.html">Youtube Video</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="single_portfolio4.html">Vimeo Video</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="single_portfolio5.html">Gallery Slider</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="single_portfolio6.html">Sound Cloud Music</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="drop-svg dropdown dropdown-7">Blog
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                     fill="none">
                  <g clip-path="url(#clip0_25_56)">
                    <path d="M6 9L12 15L18 9" stroke="white" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                  </g>
                </svg>
                <ul class="dropdown_menu dropdown_menu--animated dropdown_menu-7">
                  <li class="dropdown-submenu">
                    <a class="sub-menu-arrow-main" href="#">Blog</a>
                    <ul class="dropdown-submenu-main id-portfolio">
                      <li class="dropdown_item-submenu">
                        <a href="/blog">Blog Default</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="blog2.html">Blog 2 Grid</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="blog3.html">Blog 3 Grid</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="blog4.html">Blog Right Sidebar</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="blog5.html">Blog Left Sidebar</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="blog6.html">Blog Fullwidth</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="blog7.html">Blog list</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="blog8.html">Compact Metro</a>
                      </li>
                    </ul>
                  </li>
                  <li class="dropdown-submenu">
                    <a class="sub-menu-arrow-main" href="#">Single Blog</a>
                    <ul class="dropdown-submenu-main id-single-portfolio">
                      <li class="dropdown_item-submenu">
                        <a href="single_/blog">Standard</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="single_blog2.html">Single Blog V.2</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="single_blog3.html">Youtube Video</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="single_blog4.html">Vimeo Video</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="single_blog5.html">Gallery Slider</a>
                      </li>
                      <li class="dropdown_item-submenu">
                        <a href="single_blog6.html">Sound Cloud Music</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li><a href="contact.html">Contact</a></li>
            </ul>
            <a href="../../public/pdf/olivia_resume.pdf">
              <div class="cv-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                     fill="none">
                  <g clip-path="url(#clip0_25_74)">
                    <path d="M12 3V15" stroke="#0F141C" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                    <path d="M16 11L12 15L8 11" stroke="#0F141C" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21C14.3869 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12"
                        stroke="#0F141C" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                  </g>
                </svg>
              </div>
            </a>
            <div class="outer-menu">
              <input class="checkbox-toggle" type="checkbox" name="checkbox">
              <div class="hamburger">
                <div></div>
              </div>
              <!-- ====================================== Side Menu ===================================== -->
              <div class="menu">
                <div>
                  <nav>
                    <div class="container">
                      <div class="row sidemenu-main">
                        <div class="col-lg-2">
                          <div class="menu-text">
                            <div class="side-menu-text">
                              <p>MENU</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-7 side-menu-scroll">
                          <div class="heading2"><a href="index.html">Home</a></div>
                          <div class="heading2"><a href="about.html">About</a></div>
                          <div class="accordion">
                            <div class="heading2 heading cursor-arrow">Portfolio</div>
                            <div class="contents">
                              <ul>
                                <li><a href="portfolio.html">Portfolio Default</a></li>
                                <li><a href="portfolio2.html">Portfolio Grid</a></li>
                                <li><a href="portfolio3.html">Portfolio Split</a></li>
                                <li><a href="portfolio4.html">Portfolio Split Video</a>
                                </li>
                                <li><a href="portfolio5.html">Portfolio Split Slider</a>
                                </li>
                                <li><a href="portfolio6.html">Portfolio Full Screen</a>
                                </li>
                                <li><a href="portfolio7.html">Split Gallery</a></li>
                                <li><a href="portfolio8.html">Grid Gallery</a>
                                </li>
                                <li><a href="portfolio9.html">Full Screen Gallery</a>
                                </li>
                              </ul>
                            </div>
                            <div class="heading2 heading">Single Portfolio</div>
                            <div class="contents">
                              <ul>
                                <li><a href="single_portfolio.html">Standard</a></li>
                                <li><a href="single_portfolio2.html">Portfolio V.2</a></li>
                                <li><a href="single_portfolio3.html">Youtube Video</a></li>
                                <li><a href="single_portfolio4.html">Vimeo Video</a></li>
                                <li><a href="single_portfolio5.html">Gallery Slider</a></li>
                                <li><a href="single_portfolio6.html">Sound Cloud Music</a></li>
                              </ul>
                            </div>
                            <div class="heading2 heading">Services</div>
                            <div class="contents">
                              <ul>
                                <li><a href="services.html">Services Default</a></li>
                                <li><a href="services2.html">Services Row</a></li>
                                <li><a href="services3.html">Services 3 Grid</a></li>
                                <li><a href="services4.html">Services 2 Grid</a></li>
                                <li><a href="services5.html">FullScreen</a></li>
                              </ul>
                            </div>
                            <div class="heading2 heading">Single Services</div>
                            <div class="contents">
                              <ul>
                                <li><a href="single_services.html">Standard</a></li>
                                <li><a href="single_services2.html">Youtube Video</a></li>
                                <li><a href="single_services3.html">Vimeo Video</a></li>
                                <li><a href="single_services4.html">Gallery Slider</a></li>
                                <li><a href="single_services5.html">Sound Cloud Music</a></li>
                              </ul>
                            </div>
                            <div class="heading2 heading cursor-arrow">Blog</div>
                            <div class="contents">
                              <ul>
                                <li><a href="/blog">Blog Default</a></li>
                                <li><a href="blog2.html">Blog 2 Grid</a></li>
                                <li><a href="blog3.html">Blog 3 Grid</a></li>
                                <li><a href="blog4.html">Blog Right Sidebar</a></li>
                                <li><a href="blog5.html">Blog Left Sidebar</a></li>
                                <li><a href="blog6.html">Blog Fullwidth</a></li>
                                <li><a href="blog7.html">Blog list</a></li>
                                <li><a href="blog8.html">Compact Metro</a></li>
                              </ul>
                            </div>
                            <div class="heading2 heading">Single Blog</div>
                            <div class="contents">
                              <ul>
                                <li><a href="single_/blog">Standard</a></li>
                                <li><a href="single_blog2.html">Single Blog V.2</a></li>
                                <li><a href="single_blog3.html">Youtube Video</a></li>
                                <li><a href="single_blog4.html">Vimeo Video</a></li>
                                <li><a href="single_blog5.html">Gallery Slider</a></li>
                                <li><a href="single_blog6.html">Sound Cloud Music</a></li>
                              </ul>
                            </div>
                          </div>
                          <div class="heading2"><a href="contact.html">Contact</a></div>
                        </div>
                        <div class="col-lg-3">
                          <div class="cont-info">
                            <div class="item">
                              <p class="sub-title-address">Address</p>
                              <p class="geek-alto">121 Parkview St.<br>California, USA</p>
                            </div>
                            <div class="item">
                              <p class="sub-title-address">Contact Us</p>
                              <p><a href="mailto:mqondisixndlovu@gmail.com">mqondisixndlovu@gmail.com</a></p>
                              <p class="underline">
                                <a href="tel:+1451356888">+1 451 356 888</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <!-- ====================================== Side Menu End===================================== -->
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- ====================================== Main Menu End===================================== -->
    <!-- ====================================== Section One ===================================== -->
    <section class="section-main overflow-hidden" id="main">
      <div class="section-main-sub position-relative">
        <div class="media-main">
          <img src="../../public/images/media-line-top.svg" alt="media-line-top">
          <div>
            <a href="mailto:mqondisixndlovu@gmail.com">
              <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                   viewBox="0 0 20 20" fill="none">
                <g clip-path="url(#clip0_37_120)">
                  <path
                      d="M2.5 5.83333C2.5 5.39131 2.67559 4.96738 2.98816 4.65482C3.30072 4.34226 3.72464 4.16667 4.16667 4.16667H15.8333C16.2754 4.16667 16.6993 4.34226 17.0118 4.65482C17.3244 4.96738 17.5 5.39131 17.5 5.83333V14.1667C17.5 14.6087 17.3244 15.0326 17.0118 15.3452C16.6993 15.6577 16.2754 15.8333 15.8333 15.8333H4.16667C3.72464 15.8333 3.30072 15.6577 2.98816 15.3452C2.67559 15.0326 2.5 14.6087 2.5 14.1667V5.83333Z"
                      stroke="white" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  <path d="M2.5 5.83333L10 10.8333L17.5 5.83333" stroke="white" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_37_120">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
          <div>
            <a href="https://dribbble.com/">
              <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                   viewBox="0 0 20 20" fill="none">
                <g clip-path="url(#clip0_37_124)">
                  <path
                      d="M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10Z"
                      stroke="white" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  <path d="M7.5 3C11.6667 8 13.3333 11.75 13.75 16.5" stroke="white"
                        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M5.33334 15.8333C8.25001 12.9167 10.3333 10.4167 17.4167 10.5"
                        stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                  <path d="M2.58334 8.95833C6.75001 8.95833 10.7617 8.64166 15.345 4.79166"
                        stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_37_124">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
          <div>
            <a href="https://www.behance.net/">
              <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                   viewBox="0 0 20 20" fill="none">
                <g clip-path="url(#clip0_37_130)">
                  <path
                      d="M2.5 15V5H6.25C6.91304 5 7.54893 5.26339 8.01777 5.73223C8.48661 6.20107 8.75 6.83696 8.75 7.5C8.75 8.16304 8.48661 8.79893 8.01777 9.26777C7.54893 9.73661 6.91304 10 6.25 10C6.91304 10 7.54893 10.2634 8.01777 10.7322C8.48661 11.2011 8.75 11.837 8.75 12.5C8.75 13.163 8.48661 13.7989 8.01777 14.2678C7.54893 14.7366 6.91304 15 6.25 15H2.5Z"
                      stroke="white" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  <path d="M2.5 10H6.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                  <path
                      d="M11.6667 10.8333H17.5C17.5 10.0598 17.1927 9.31792 16.6457 8.77094C16.0987 8.22396 15.3569 7.91667 14.5833 7.91667C13.8098 7.91667 13.0679 8.22396 12.5209 8.77094C11.9739 9.31792 11.6667 10.0598 11.6667 10.8333ZM11.6667 10.8333V12.5C11.7526 13.1239 12.0382 13.7032 12.4807 14.1513C12.9232 14.5994 13.4989 14.8923 14.1217 14.9861C14.7444 15.0798 15.3808 14.9695 15.9357 14.6716C16.4906 14.3737 16.9341 13.9042 17.2 13.3333"
                      stroke="white" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  <path d="M13.3333 5H15.8333" stroke="white" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_37_130">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
          <div>
            <a href="https://www.youtube.com/">
              <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                   viewBox="0 0 20 20" fill="none">
                <g clip-path="url(#clip0_37_136)">
                  <path
                      d="M1.66666 6.66666C1.66666 5.78261 2.01785 4.93476 2.64297 4.30964C3.26809 3.68452 4.11593 3.33333 4.99999 3.33333H15C15.884 3.33333 16.7319 3.68452 17.357 4.30964C17.9821 4.93476 18.3333 5.78261 18.3333 6.66666V13.3333C18.3333 14.2174 17.9821 15.0652 17.357 15.6903C16.7319 16.3155 15.884 16.6667 15 16.6667H4.99999C4.11593 16.6667 3.26809 16.3155 2.64297 15.6903C2.01785 15.0652 1.66666 14.2174 1.66666 13.3333V6.66666Z"
                      stroke="white" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  <path d="M8.33334 7.5L12.5 10L8.33334 12.5V7.5Z" stroke="white" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_37_136">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
          <img src="../../public/images/media-line-bottom.svg" alt="media-line-bottom">
        </div>
        <div class="container2 position-relative overflow-hidden">
          <p class="main-text" data-aos="fade-down">Hello, I am <span>👋</span></p>
          <h1><mark>Web</mark> Developer.</h1>
          <div class="home-girl-img-main">
            <div class="position-relative">
              <div class="JessicaBiogi-main" data-aos="fade-down">
                <div class="position-relative" id="JessicaBiogi_main">
                  <img class="JessicaBiogi-svg" src="../../public/images/Polygon-arrow.svg"
                       alt="Polygon-arrow">
                  <p class="mqondisi-ndlovu">Mqondisi Ndlovu</p>
                </div>
              </div>
              <img class="home-girl-img" src="../../public/images/girl.png" alt="girl" data-aos="fade-up">
              <img class="arrow-main" id="arrow" src="../../public/images/arrow-main.png" alt="arrow-main">
              <div id="worked_box">
                <div class="worked-box" data-aos="fade-right">
                  <p class="worked-more">Worked with more than 100 people</p>
                  <div class="client-img-main position-relative">
                    <img class="client-img client-img1" src="../../public/images/clients-img1.jpg"
                         alt="clients-img1">
                    <img class="client-img client-img2" src="../../public/images/clients-img2.jpg"
                         alt="clients-img2">
                    <img class="client-img client-img3" src="../../public/images/clients-img3.jpg"
                         alt="clients-img3">
                    <img class="client-img client-img4" src="../../public/images/clients-img4.jpg"
                         alt="clients-img4">
                    <p class="worked-more worked-more2"> 100+ Clients</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section-main-right-contain overflow-hidden">
          <div class="section-main-right-contain-sub" data-aos="fade-left">
            <h2 class="total-project">700+</h2>
            <p class="project-complted">PROJECT COMPLETED</p>
          </div>
          <div class="flower-box" data-aos="fade-left">
            <img class="flower" src="../../public/images/flower.png" alt="flower">
            <h2 class="Freelance">A <span>Freelance Designer and Developer based in USA.</span> I
              strives to build immersive and beautiful web applications through carefully crafted
              used-centric design.</h2>
          </div>
          <div class="mouse-scroll-box">
            <a href="#section-two" class="mouse-main">
              <div class="mouse">
                <svg class="mouse-arrow-down" xmlns="http://www.w3.org/2000/svg" width="24" height="40"
                     viewBox="0 0 24 40" fill="none">
                  <g clip-path="url(#clip0_37_162)">
                    <path d="M12 5L12 35" stroke="#FFDB59" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round" />
                    <path d="M16 31L12 35" stroke="#FFDB59" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8 31L12 35" stroke="#FFDB59" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0_37_162">
                      <rect width="24" height="40" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <h2 class="scroll-down">SCROLL DOWN</h2>
            </a>
          </div>
        </div>
      </div>
    </section>
    <!-- ====================================== Section One End ===================================== -->
    <!-- ====================================== Section Two ===================================== -->
    <section class="section-two overflow-hidden" id="section-two">
      <div class="container">
        <div class="row section-two-row">
          <div class="col-xxl-6 col-xl-6 col-lg-6 position-relative section-two-col-one" data-aos="fade-up">
            <img class="girl-img2" src="../../public/images/girl-img2.png" alt="girl-img2" data-aos="fade-left">
            <div class="hwlo">
              <img class="years-img" src="../../public/images/years-img.png" alt="years-img">
              <div class="digit">6</div>
              <div class="circle-text">YEARS OF<br> WORKING EXPERICE</div>
            </div>
          </div>
          <div class="col-xxl-6 col-xl-6 col-lg-6">
            <div class="award-circle-main" data-aos="zoom-in">
              <div class="award-circle">
                <img src="../../public/images/trophy.png" alt="trophy">
              </div>
              <div class="">
                <h3 class="independent">Independent Of The Year</h3>
                <p class="annual">Annual Awards 2025</p>
              </div>
            </div>
            <h3 class="self-taught" data-aos="fade-up">Hello! I’m <span>Jessica</span> a self-taught &
              award-winning<span> Web Designer & Developer</span> with over <span>fifteen years of work
                                experience</span>. I started in my children’s room and got pro at renowned <span>Ren
                                Tech</span> Agencies.
            </h3>
            <p class="odio" data-aos="fade-up">Urna neque sollicitudin odio tempus eleifend est. Vestibulum
              dictum turpis diam arcu rutrum interdum aliquet iaculis.
            </p>
            <p class="explore-me" data-aos="fade-right">
              <a href="about.html#explore-me">EXPLORE ME
                <svg class="expo-arrow" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                     viewBox="0 0 32 32" fill="none">
                  <g>
                    <path d="M6.66669 16H25.3334" stroke="#FFDB59" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M20 21.3333L25.3333 16" stroke="#FFDB59" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M20 10.6666L25.3333 16" stroke="#FFDB59" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round" />
                  </g>
                </svg>
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- ====================================== Section Two End ===================================== -->
    <!-- ====================================== Brands Marquee Section ===================================== -->
    <div class="Marquee-main overflow-hidden">
      <h3 data-aos="fade-down">Trusted by World Leading Brands</h3>
      <div class="logos logos2">
        <div class="logos-slide">
          <div class="marquee__content">
            <div class="marquee-img-main">
              <img class="brand-logos" src="../../public/images/brand-logo1.png" alt="brand-logo1">
            </div>
            <div class="marquee-img-main">
              <img class="brand-logos" src="../../public/images/brand-logo2.png" alt="brand-logo2">
            </div>
            <div class="marquee-img-main">
              <img class="brand-logos" src="../../public/images/brand-logo3.png" alt="brand-logo3">
            </div>
            <div class="marquee-img-main">
              <img class="brand-logos" src="../../public/images/brand-logo4.png" alt="brand-logo4">
            </div>
            <div class="marquee-img-main">
              <img class="brand-logos" src="../../public/images/brand-logo5.png" alt="brand-logo5">
            </div>
            <div class="marquee-img-main">
              <img class="brand-logos" src="../../public/images/brand-logo6.png" alt="brand-logo6">
            </div>
            <div class="marquee-img-main">
              <img class="brand-logos" src="../../public/images/brand-logo7.png" alt="brand-logo7">
            </div>
            <div class="marquee-img-main">
              <img class="brand-logos" src="../../public/images/brand-logo8.png" alt="brand-logo8">
            </div>
            <div class="marquee-img-main">
              <img class="brand-logos" src="../../public/images/brand-logo7.png" alt="brand-logo9">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- =============================== Brands Marquee Section End =============================== -->
    <!-- ====================================== Section Three ===================================== -->
    <section class="section-three overflow-hidden">
      <div class="container">
        <div class="row services-section">
          <div class="col-xxl-4 col-xl-4 col-lg-4">
            <p class="our-services" data-aos="fade-up">OUR SERVICES</p>
            <h2 class="our-solution" data-aos="fade-up">Solution We Provide.</h2>
            <p class="uran odio" data-aos="fade-up">Urna neque sollicitudin odio tempus eleifend est.
              Vestibulum dictum turpis diam arcu rutrum interdum aliquet iaculis.
            </p>
            <div class="services-circle-main">
              <div class="view-work-circle btn btn1" id="circle1" data-aos="zoom-in-right">
                <a href="portfolio.html" class="a1">VIEW MY WORK</a>
              </div>
              <div class="contact-circle" data-aos="zoom-in-left">
                <div class="view-work-circle btn btn2">
                  <a href="contact.html">CONTACT ME</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-7 col-xl-7 col-lg-7">
            <div id="faq-sec">
              <div class='nested-accordion' data-aos="fade-up">
                <h3 class="boder-top selected">Visual Design</h3>
                <div class='comment'>Project systematization is something I place a lot of emphasis on.
                  My passion Design Systems.
                  <ul class="accor-list">
                    <li>Interface Design</li>
                    <li>Creating Design Systems</li>
                    <li>UI Kits</li>
                    <li>User Experience Development</li>
                  </ul>
                </div>
              </div>
              <div class='nested-accordion' data-aos="fade-up">
                <h3 class="boder-top ">Design Systems</h3>
                <div class='comment'>Project systematization is something I place a lot of emphasis on.
                  My passion Design Systems.
                  <ul class="accor-list">
                    <li>Interface Design</li>
                    <li>Creating Design Systems</li>
                    <li>UI Kits</li>
                    <li>User Experience Development</li>
                  </ul>
                </div>
              </div>
              <div class='nested-accordion' data-aos="fade-up">
                <h3 class="boder-top">Web Design & Development</h3>
                <div class='comment'>Project systematization is something I place a lot of emphasis on.
                  My passion Design Systems.
                  <ul class="accor-list">
                    <li>Interface Design</li>
                    <li>Creating Design Systems</li>
                    <li>UI Kits</li>
                    <li>User Experience Development</li>
                  </ul>
                </div>
              </div>
              <div class='nested-accordion' data-aos="fade-up">
                <h3 class="boder-top border-bottom-0 pb-0">Design Systems</h3>
                <div class='comment'>Project systematization is something I place a lot of emphasis on.
                  My passion Design Systems.
                  <ul class="accor-list">
                    <li>Interface Design</li>
                    <li>Creating Design Systems</li>
                    <li>UI Kits</li>
                    <li>User Experience Development</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ====================================== Section Three End===================================== -->
    <!-- ====================================== User Branding Marquee Section ===================================== -->
    <div class="header_full_sec overflow-hidden">
      <div class="logos">
        <div class="logos-slide">
          <div class="ticker__item">User Interface</div>
          <div class="ticker__item ticker__item_dark">User Experience</div>
          <div class="ticker__item">Branding</div>
          <div class="ticker__item ticker__item_dark">Marketing</div>
          <div class="ticker__item">User Interface</div>
          <div class="ticker__item ticker__item_dark">User Experience</div>
          <div class="ticker__item">Branding</div>
          <div class="ticker__item ticker__item_dark">Marketing</div>
          <div class="ticker__item">User Interface</div>
          <div class="ticker__item ticker__item_dark">User Experience</div>
        </div>
      </div>
    </div>
    <!-- ====================================== User Branding Marquee Section End ===================================== -->
    <!-- ====================================== Section Four ===================================== -->
    <section class="section-four overflow-hidden">
      <div class="container">
        <p class="our-services my_skills" data-aos="fade-up">MY SKILLS</p>
        <h2 class="our-solution my-expertise" data-aos="fade-down">My Expertise.</h2>
        <div class="skill-type-main">
          <div class="skills-box-main" data-aos="flip-left" data-aos-easing="ease-out-cubic"
               data-aos-duration="2000">
            <img src="../../public/images/wordpress.png" alt="wordpress">
            <div class="counter">
              <h3 class="count count2" data-count="98">0</h3>
              <p>WORDPRESS</p>
            </div>
          </div>
          <div class="skills-box-main" data-aos="flip-left" data-aos-easing="ease-out-cubic"
               data-aos-duration="2000">
            <img src="../../public/images/elementor.png" alt="elementor">
            <div class="counter">
              <h3 class="count count2" data-count="86">86%</h3>
              <p>ELEMENTOR</p>
            </div>
          </div>
          <div class="skills-box-main" data-aos="flip-left" data-aos-easing="ease-out-cubic"
               data-aos-duration="2000">
            <img src="../../public/images/html-logo.png" alt="html-logo">
            <div class="counter">
              <h3 class="count count2" data-count="95">95%</h3>
              <p>HTML5</p>
            </div>
          </div>
          <div class="skills-box-main" data-aos="flip-left" data-aos-easing="ease-out-cubic"
               data-aos-duration="2000">
            <img src="../../public/images/js-logo.png" alt="js-logo">
            <div class="counter">
              <h3 class="count count2" data-count="89">89%</h3>
              <p>JAVASCRIPT</p>
            </div>
          </div>
          <div class="skills-box-main" data-aos="flip-left" data-aos-easing="ease-out-cubic"
               data-aos-duration="2000">
            <img src="../../public/images/figma.png" alt="figma">
            <div class="counter">
              <h3 class="count count2" data-count="96">96%</h3>
              <p>FIGMA</p>
            </div>
          </div>
          <div class="skills-box-main" data-aos="flip-left" data-aos-easing="ease-out-cubic"
               data-aos-duration="2000">
            <img src="../../public/images/diamond.png" alt="diamond">
            <div class="counter">
              <h3 class="count count2" data-count="87">87%</h3>
              <p>SKETCH</p>
            </div>
          </div>
          <div class="skills-box-main" data-aos="flip-left" data-aos-easing="ease-out-cubic"
               data-aos-duration="2000">
            <img src="../../public/images/photoshop.png" alt="photoshop">
            <div class="counter">
              <h3 class="count count2" data-count="85">85%</h3>
              <p>PHOTOSHOP</p>
            </div>
          </div>
          <div class="skills-box-main" data-aos="flip-left" data-aos-easing="ease-out-cubic"
               data-aos-duration="2000">
            <img src="../../public/images/illustrator.png" alt="illustrator">
            <div class="counter">
              <h3 class="count count2" data-count="93">93%</h3>
              <p>ILLUSTRATOR</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-six overflow-hidden">
      <div class="container">
        <p class="our-services" data-aos="fade-up">TESTIMONIALS</p>
        <h2 class="our-solution latest_project" data-aos="fade-down">What Our Clients Say.</h2>
        <div class="testimonial_slider" data-aos="zoom-in-left">
          <div>
            <div class="great-work-svg">
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" viewBox="0 0 40 30"
                   fill="none">
                <g>
                  <path
                      d="M11.9903 8.97047L11.9834 8.98364L11.977 8.997C10.9357 11.1534 10.3315 13.3985 10.1705 15.7265L10.1087 16.6197L10.9897 16.7794L16.4359 17.7668V29H1V20.6818C1 15.4562 2.21666 11.3738 4.55213 8.33851C6.85551 5.3666 9.89607 3.01035 13.7015 1.27904L15.1132 3.79798C14.0057 5.37106 12.9651 7.09582 11.9903 8.97047ZM34.5544 8.97047L34.5475 8.98364L34.5411 8.997C33.4998 11.1534 32.8956 13.3985 32.7346 15.7265L32.6728 16.6197L33.5538 16.7794L39 17.7668V29H23.5641V20.6818C23.5641 15.4561 24.7808 11.3737 27.1163 8.33843C29.4197 5.36656 32.4602 3.01033 36.2656 1.27904L37.6773 3.79798C36.5698 5.37106 35.5292 7.09583 34.5544 8.97047Z"
                      stroke="#FF7D61" stroke-width="2" />
                </g>
              </svg>
              <h3>Great Work!</h3>
            </div>
            <p class="posuere">Ultrices sed sed ut posuere at. Risus urna augue orci malesuada etiam diam
              enim. Parturient habitasse massa feugiat posuere odio leo vitae feugiat. Scelerisque cras
              viverra nunc ipsum et duis dui proin a. Urna rhoncus nullam odio sed vitae.
            </p>
            <svg class="slider-line" xmlns="http://www.w3.org/2000/svg" height="33" viewBox="0 0 630 33"
                 fill="none">
              <path d="M630 1H100L70 31V1H0" stroke="#909090" />
            </svg>
            <div class="client_details">
              <img class="testimonial_client_img" src="../../public/images/testimonial_client_img1.jpg"
                   alt="testimonial_client_img1">
              <div>
                <h3 class="client-name">Olivia Segio</h3>
                <p class="studio">Oria Studio</p>
              </div>
            </div>
          </div>
          <div>
            <div class="great-work-svg">
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" viewBox="0 0 40 30"
                   fill="none">
                <g>
                  <path
                      d="M11.9903 8.97047L11.9834 8.98364L11.977 8.997C10.9357 11.1534 10.3315 13.3985 10.1705 15.7265L10.1087 16.6197L10.9897 16.7794L16.4359 17.7668V29H1V20.6818C1 15.4562 2.21666 11.3738 4.55213 8.33851C6.85551 5.3666 9.89607 3.01035 13.7015 1.27904L15.1132 3.79798C14.0057 5.37106 12.9651 7.09582 11.9903 8.97047ZM34.5544 8.97047L34.5475 8.98364L34.5411 8.997C33.4998 11.1534 32.8956 13.3985 32.7346 15.7265L32.6728 16.6197L33.5538 16.7794L39 17.7668V29H23.5641V20.6818C23.5641 15.4561 24.7808 11.3737 27.1163 8.33843C29.4197 5.36656 32.4602 3.01033 36.2656 1.27904L37.6773 3.79798C36.5698 5.37106 35.5292 7.09583 34.5544 8.97047Z"
                      stroke="#FF7D61" stroke-width="2" />
                </g>
              </svg>
              <h3>Best Creative Agency!</h3>
            </div>
            <p class="posuere">Lectus quis sit diam aenean. Neque sed blandit sed at. Adipiscing eu aenean
              viverra nunc. Lacus ornare massa scelerisque feugiat pretium diam massa purus. Ultrices
              elementum mattis magna cras viverra nisl enim.
            </p>
            <svg class="slider-line" xmlns="http://www.w3.org/2000/svg" height="33" viewBox="0 0 630 33"
                 fill="none">
              <path d="M630 1H100L70 31V1H0" stroke="#909090" />
            </svg>
            <div class="client_details">
              <img class="testimonial_client_img" src="../../public/images/testimonial_client_img2.jpg"
                   alt="testimonial_client_img1">
              <div>
                <h3 class="client-name">Alex Regan</h3>
                <p class="studio">Square Agency</p>
              </div>
            </div>
          </div>
          <div>
            <div class="great-work-svg">
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" viewBox="0 0 40 30"
                   fill="none">
                <g>
                  <path
                      d="M11.9903 8.97047L11.9834 8.98364L11.977 8.997C10.9357 11.1534 10.3315 13.3985 10.1705 15.7265L10.1087 16.6197L10.9897 16.7794L16.4359 17.7668V29H1V20.6818C1 15.4562 2.21666 11.3738 4.55213 8.33851C6.85551 5.3666 9.89607 3.01035 13.7015 1.27904L15.1132 3.79798C14.0057 5.37106 12.9651 7.09582 11.9903 8.97047ZM34.5544 8.97047L34.5475 8.98364L34.5411 8.997C33.4998 11.1534 32.8956 13.3985 32.7346 15.7265L32.6728 16.6197L33.5538 16.7794L39 17.7668V29H23.5641V20.6818C23.5641 15.4561 24.7808 11.3737 27.1163 8.33843C29.4197 5.36656 32.4602 3.01033 36.2656 1.27904L37.6773 3.79798C36.5698 5.37106 35.5292 7.09583 34.5544 8.97047Z"
                      stroke="#FF7D61" stroke-width="2" />
                </g>
              </svg>
              <h3>Great Work!</h3>
            </div>
            <p class="posuere">Ultrices sed sed ut posuere at. Risus urna augue orci malesuada etiam diam
              enim. Parturient habitasse massa feugiat posuere odio leo vitae feugiat. Scelerisque cras
              viverra nunc ipsum et duis dui proin a. Urna rhoncus nullam odio sed vitae.
            </p>
            <svg class="slider-line" xmlns="http://www.w3.org/2000/svg" height="33" viewBox="0 0 630 33"
                 fill="none">
              <path d="M630 1H100L70 31V1H0" stroke="#909090" />
            </svg>
            <div class="client_details">
              <img class="testimonial_client_img" src="../../public/images/testimonial_client_img3.jpg"
                   alt="testimonial_client_img3">
              <div>
                <h3 class="client-name">Andrew Lewis</h3>
                <p class="studio">Oria Studio</p>
              </div>
            </div>
          </div>
          <div>
            <div class="great-work-svg">
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" viewBox="0 0 40 30"
                   fill="none">
                <g>
                  <path
                      d="M11.9903 8.97047L11.9834 8.98364L11.977 8.997C10.9357 11.1534 10.3315 13.3985 10.1705 15.7265L10.1087 16.6197L10.9897 16.7794L16.4359 17.7668V29H1V20.6818C1 15.4562 2.21666 11.3738 4.55213 8.33851C6.85551 5.3666 9.89607 3.01035 13.7015 1.27904L15.1132 3.79798C14.0057 5.37106 12.9651 7.09582 11.9903 8.97047ZM34.5544 8.97047L34.5475 8.98364L34.5411 8.997C33.4998 11.1534 32.8956 13.3985 32.7346 15.7265L32.6728 16.6197L33.5538 16.7794L39 17.7668V29H23.5641V20.6818C23.5641 15.4561 24.7808 11.3737 27.1163 8.33843C29.4197 5.36656 32.4602 3.01033 36.2656 1.27904L37.6773 3.79798C36.5698 5.37106 35.5292 7.09583 34.5544 8.97047Z"
                      stroke="#FF7D61" stroke-width="2" />
                </g>
              </svg>
              <h3>Best Creative Agency!</h3>
            </div>
            <p class="posuere">Lectus quis sit diam aenean. Neque sed blandit sed at. Adipiscing eu aenean
              viverra nunc. Lacus ornare massa scelerisque feugiat pretium diam massa purus. Ultrices
              elementum mattis magna cras viverra nisl enim.
            </p>
            <svg class="slider-line" xmlns="http://www.w3.org/2000/svg" height="33" viewBox="0 0 630 33"
                 fill="none">
              <path d="M630 1H100L70 31V1H0" stroke="#909090" />
            </svg>
            <div class="client_details">
              <img class="testimonial_client_img" src="../../public/images/testimonial_client_img4.jpg"
                   alt="testimonial_client_img4">
              <div>
                <h3 class="client-name">Mark Daly</h3>
                <p class="studio">Square Agency</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ======================================= Section Six End ================================== -->
    <!-- ======================================= Section Seven ================================== -->
    <section class="section-seven overflow-hidden">
      <div class="container">
        <p class="our-services" data-aos="fade-up">NEWS & LATEST UPDATES</p>
        <div class="news-header">
          <div>
            <h2 class="our-solution latest_project" data-aos="fade-down">Check Inside Story.</h2>
          </div>
          <div>
            <button class="explore-btn hover-slide-right see-more-btn"
                    onclick="window.location.href='/blog';">
                            <span>SEE MORE NEWS
                                <svg class="expo-arrow2" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                     viewBox="0 0 32 32" fill="none">
                                    <g>
                                        <path d="M6.66669 16H25.3334" stroke="#FFDB59" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M20 21.3333L25.3333 16" stroke="#FFDB59" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M20 10.6666L25.3333 16" stroke="#FFDB59" stroke-width="2"
                                              stroke-linecap="round" stroke-linejoin="round"></path>
                                    </g>
                                </svg>
                            </span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 new-img-box-main" data-aos="fade-up">
            <img class="news-img" src="../../public/images/news-img1.jpg" alt="news-img1"
                 onclick="window.location.href='/blog'">
            <p class=" date-dev">APRIL 25, 2024 _ DEVELOPMENT</p>
            <h2 class="need-blog" onclick="window.location.href='/blog';">Does My Website Need a Blog?
            </h2>
            <p class="explore-me read-more-btn">
              <a href="/blog">READ MORE
                <svg class="expo-arrow" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                     viewBox="0 0 32 32" fill="none">
                  <g>
                    <path d="M6.66669 16H25.3334" stroke="#FFDB59" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M20 21.3333L25.3333 16" stroke="#FFDB59" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M20 10.6666L25.3333 16" stroke="#FFDB59" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                  </g>
                </svg>
              </a>
            </p>
          </div>
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 new-img-box-main" data-aos="fade-down">
            <img class="news-img" src="../../public/images/news-img2.jpg" alt="news-img2"
                 onclick="window.location.href='/blog';">
            <p class="date-dev">APRIL 26, 2024 _ DESIGN</p>
            <h2 class="need-blog" onclick="window.location.href='/blog';">Best 10 App for Digital
              Transformation</h2>
            <p class="explore-me read-more-btn">
              <a href="/blog">READ MORE
                <svg class="expo-arrow" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                     viewBox="0 0 32 32" fill="none">
                  <g>
                    <path d="M6.66669 16H25.3334" stroke="#FFDB59" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M20 21.3333L25.3333 16" stroke="#FFDB59" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M20 10.6666L25.3333 16" stroke="#FFDB59" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                  </g>
                </svg>
              </a>
            </p>
          </div>
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 new-img-box-main" data-aos="fade-up">
            <img class="news-img" src="../../public/images/news-img3.jpg" alt="news-img3"
                 onclick="window.location.href='/blog';">
            <p class="date-dev">APRIL 28, 2024 _ MARKETING</p>
            <h2 class="need-blog" onclick="window.location.href='/blog';">A Simple Social Media
              Checklist</h2>
            <p class="explore-me read-more-btn">
              <a href="/blog">READ MORE
                <svg class="expo-arrow" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                     viewBox="0 0 32 32" fill="none">
                  <g>
                    <path d="M6.66669 16H25.3334" stroke="#FFDB59" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M20 21.3333L25.3333 16" stroke="#FFDB59" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M20 10.6666L25.3333 16" stroke="#FFDB59" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                  </g>
                </svg>
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="header_full_sec" id="contact-ticker">
        <div class="position-relative">
          <img class="client-bounce-img client-bounce-img1" src="../../public/images/client-bounce-img1.jpg"
               alt="client-bounce-img1">
          <img class="client-bounce-img client-bounce-img2" src="../../public/images/client-bounce-img2.jpg"
               alt="client-bounce-img2">
          <img class="client-bounce-img client-bounce-img3" src="../../public/images/client-bounce-img3.jpg"
               alt="client-bounce-img3">
          <img class="client-bounce-img client-bounce-img4" src="../../public/images/client-bounce-img4.jpg"
               alt="client-bounce-img4">
          <div class="logos">
            <div class="logos-slide">
              <div class="ticker__item">
                <a href="mailto:mqondisixndlovu@gmail.com">mqondisixndlovu@gmail.com</a>
              </div>
              <div class="ticker__item ticker__item_dark">
                <a href="tel:+263717468729">+263 717 468 729</a>
              </div>
              <div class="ticker__item">
                <a href="mailto:mqondisixndlovu@gmail.com">mqondisixndlovu@gmail.com</a>
              </div>
              <div class="ticker__item ticker__item_dark">
                <a href="tel:+263717468729">+263 717 468 729</a>
              </div>
              <div class="ticker__item">
                <a href="mailto:mqondisixndlovu@gmail.com">mqondisixndlovu@gmail.com</a>
              </div>
              <div class="ticker__item ticker__item_dark">
                <a href="tel:+263717468729">+263 717 468 729</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ====================================== Section Seven End ===================================== -->
    <AppFooter />
    <!-- ====================================== Progress Scroll Bottom To Top ===================================== -->
    <div class="progress-wrap cursor-pointer">
      <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
      </svg>
    </div>
    <!-- ====================================== Progress Scroll Bottom To Top End ===================================== -->
  </div>
</template>

<script>
// @ is an alias to /src

import AppFooter from "@/components/Footer.vue";

export default {
  name: 'HomeView',
  components: {
    AppFooter
  }
}
</script>
